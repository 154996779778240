import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const EmptyList = () => {
    const navigate = useNavigate();

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '1rem', gap: '0.5rem'}}>
            <FontAwesomeIcon icon={faShuffle} size={'10x'} style={{color: 'gainsboro'}}/>
            <h1>Список сравнения пуст</h1>
            <label>Вы можете найти подходящие для сравнения товары на странице "Каталог"</label>
            <Button bsPrefix="btn button_main_color-rounded" style={{width: '12rem', height: '3rem'}} onClick={() => navigate('/catalogue')}>
                {'Вернуться в магазин'.toUpperCase()}
            </Button>
        </div>
    )
}
