//Гарантия
import PageTitle from '../../components/page-title/pageTitle';
import { Button } from 'react-bootstrap';
import { PhoneLink } from '../../components/phone_lev_template';

const Warranty = () => {
    return (
        <div className="warranty">
            <PageTitle title="Гарантия" bottomText="Главная / Гарантия" />
            <div className="static-page_text">
                <p>
                    <strong>
                        Наш магазин предоставляет бесплатную гарантию на
                        следующие виды товаров:
                    </strong>
                </p>
                <ol>
                    <li>
                        12 или более месяцев: на все новые мобильные компьютеры
                    </li>
                    <li>
                        3 или более месяцев: на батареи, адаптеры и прочие
                        комплектующие для компьютера
                    </li>
                    <li>
                        6 или более месяцев: на наушники, мыши, гарнитуры и
                        прочие аксессуары для мобильных компьютеров.
                    </li>
                    <li>
                        Также наш сервисный центр предлагает льготные условия по
                        ремонту компьютерной техники, в постгарантийный период.
                    </li>
                </ol>
                <p>
                    <strong>
                        ПРАВИЛА СЕРВИСНОГО ЦЕНТРА (бесплатное гарантийное
                        обслуживание)
                    </strong>
                </p>
                <ol>
                    <li>
                        Бесплатное гарантийное обслуживание возможно ТОЛЬКО для
                        клиентов, купивших данный ноутбук в компании UltraNout
                        или у нашего розничного представителя, при обращении по
                        гарантии Вам потребуется предъявить ЧЕК и ГАРАНТИЙНЫЙ
                        ТАЛОН, сохраните их, пожалуйста!
                    </li>
                    <li>
                        Перед назначением диагностики менеджер вправе запросить
                        серийный номер вашего устройства для проверки и
                        установления факта покупки в нашем магазине.
                    </li>
                    <li>
                        Диагностика неисправности может занять до 10 дней, мы
                        работаем по законодательству РФ.
                    </li>
                    <li>
                        Если на вашем устройстве установлены пароли, сообщите
                        нам или заблаговременно удалите их.
                    </li>
                    <li>
                        Наш сервис не несёт ответственности за данные,
                        находящиеся на компьютере пользователя. В процессе
                        диагностики может понадобиться переустановка системы,
                        влекущая за собой утрату данных. Пожалуйста, СОХРАНЯЙТЕ
                        ДАННЫЕ самостоятельно или попросите нас помочь в этом.
                    </li>
                    <li>
                        Время ремонта определяется индивидуально. Наш сервис
                        работает профессионально, аккуратно и вдумчиво, по этой
                        причине у нас нет услуги «срочного ремонта» – будьте
                        готовы что процесс займёт время.
                    </li>
                    <li>Случаи, не являющиеся гарантийными:</li>
                </ol>
                <p style={{ paddingLeft: '80px' }}>
                    – неисправность ноутбука по причине механических
                    повреждений;
                </p>
                <p style={{ paddingLeft: '80px' }}>
                    – неисправность ноутбука, вызванная воздействием жидкости
                    или газов (пролили на ноутбук жидкость или обнаружился
                    конденсат внутри ноутбука);
                </p>
                <p style={{ paddingLeft: '80px' }}>
                    – некорректные воздействия на софт устройства (попытки
                    самостоятельно «перепрошить» БИОС);
                </p>
                <p style={{ paddingLeft: '80px' }}>
                    – перебои электропитания, короткое замыкание;
                </p>
                <p style={{ paddingLeft: '80px' }}>
                    – а также все случаи, определённые производителем как не
                    подлежащие бесплатной гарантии.
                </p>
                <ol start="8">
                    <li>
                        Срок бесплатного гарантийного обслуживания указан в
                        вашем гарантийном талоне, сверяйте даты перед
                        обращением! Для наших клиентов доступны также льготные
                        условия по ремонту компьютерной техники в
                        постгарантийный период.
                    </li>
                    <li>
                        Отправка/доставка до нашего сервисного центра и обратно
                        осуществляется ЗА СЧЁТ ОТПРАВИТЕЛЯ.
                    </li>
                </ol>
                <p>
                    <strong>
                        ПРАВИЛА ПОСТГАРАНТИЙНОГО ОБСЛУЖИВАНИЯ (платное сервисное
                        обслуживание):
                    </strong>
                </p>
                <ol>
                    <li>
                        Постгарантийное обслуживание возможно ТОЛЬКО для
                        клиентов, купивших данный ноутбук в компании UltraNout
                        или у одного из наших розничных представителей. Данное
                        условие может быть пересмотрено в индивидуальном
                        порядке, подробности следует уточнять у менеджера.
                    </li>
                    <li>
                        Перед назначением диагностики менеджер вправе запросить
                        серийный номер вашего устройства для проверки и
                        установления факта покупки в нашем магазине.
                    </li>
                    <li>
                        Диагностика неисправности проводится БЕСПЛАТНО и может
                        занять до 10 дней, мы работаем по законодательству РФ.
                    </li>
                    <li>
                        Если на вашем устройстве установлены пароли, сообщите
                        нам или заблаговременно удалите их.
                    </li>
                    <li>
                        Наш сервис не несёт ответственности за данные,
                        находящиеся на компьютере пользователя. В процессе
                        диагностики может понадобиться переустановка системы,
                        влекущая за собой утрату данных. Пожалуйста, СОХРАНЯЙТЕ
                        ДАННЫЕ самостоятельно или попросите нас помочь в этом.
                    </li>
                    <li>
                        После диагностики специалист индивидуально определяет
                        варианты решения проблемы, стоимость и время ремонта,
                        если предложенные услуги не подходят по мнению
                        заказчика, клиент вправе отказаться и забрать свою
                        технику из ремонта.- Стоимость работ рассчитывается СО
                        СКИДКОЙ 40%, стоимость комплектующих берётся по
                        себестоимости, без наценки
                        <br />– Время ремонта определяется индивидуально. Наш
                        сервис работает профессионально, аккуратно и вдумчиво,
                        по этой причине у нас нет услуги «срочного ремонта» –
                        пожалуйста, будьте готовы что процесс займёт время
                    </li>
                    <li>
                        Компания UltraNout берёт оплату только в случае
                        успешного ремонта, по заранее согласованному перечню
                        работ. В случае неудачного ремонта, расходы по
                        произведённым работам оплачивает компания UltraNout.
                        Финансовые издержки, связанные с возможными задержками в
                        сроках ремонта, несёт заказчик.
                    </li>
                    <li>
                        Отправка/доставка до нашего сервисного центра и обратно
                        осуществляется ЗА СЧЁТ ОТПРАВИТЕЛЯ.
                    </li>
                </ol>
                <p>
                    <strong>
                        Возврат денежных средств при возврате оплаченного
                        товара:
                    </strong>
                </p>
                <p>
                    При возврате ранее приобретенного товара денежные средства
                    возвращаются покупателю тем же способом каким была
                    осуществлена покупка товара. При этом сроки возврата
                    составляют:
                </p>
                <p>
                    – при возврате на расчетный счет (банковским переводом) –
                    4-5 рабочих дней;
                </p>
                <p>
                    – при возврате на банковскую карту – перевод суммы на карту
                    осуществляется банком-эмитентом в необходимый банку период
                    времени;
                </p>
                <p>
                    * Для покупателей из Москвы и Московской области возврат
                    товара осуществляется через магазин нашего розничного
                    представителя по адресу: 127018, г. Москва, ул. Сущевский
                    вал, д. 5 стр. 1А, ТК “Савеловский” пав. F-52
                </p>
                <p>
                    * Для покупателей из регионов России возврат
                    инициализируется по заявлению, копию заявление нужно
                    отправить на e-mail: ultranout@gmail.com и приложить к
                    посылке.
                </p>
                <p>
                    * Доставка товара до нашего магазина (сервисного центра), а
                    также его доставка до покупателя обратно, осуществляется
                    силами заявителя (Покупателя). После получения товара,
                    рассмотрения заявления и подтверждения наличия заявленных
                    дефектов, возникших не по вине Покупателя, продавцом
                    производится устранение возникших дефектов или неполадок
                    нашим сервисным центром или авторизованным сервисным центром
                    производителя товара.
                </p>
                <p>
                    <strong>
                        АДРЕС СДЕК ДЛЯ ВОЗВРАТА ИЛИ ОТПРАВКИ ТОВАРА НА
                        ДИАГНОСТИКУ:
                    </strong>
                </p>
                <p>
                    * Отправлять нужно до пункта выдачи заказов (ПВЗ) номер
                    MSK93, по адресу: Москва, ул. Сущевский вал, 5, стр.1, А029
                    на имя получателя: Родин Антон Вячеславович +7 968
                    084-55-53, отправка за счёт отправителя!
                </p>
                <p>
                    * ОБЯЗАТЕЛЬНО обмотайте упаковку полиэтиленом (есть в
                    отделениях СДЕК) – перед тем, как клеить сверху скотч
                    «СДЕК». Рекомендуем: наклеить значок «хрупкое» (есть в
                    отделениях СДЭК), а также взять страховку на 100% от
                    реальной стоимости. Отправка (доставка) до нашего сервисного
                    центра осуществляется ЗА СЧЁТ ОТПРАВИТЕЛЯ. После отправки
                    просьба прислать трек-номер.
                </p>
                <p>
                    <strong>Контактные данные по вопро</strong>
                    <strong>
                        сам, относящихся к возврату и обмену товара.
                    </strong>
                </p>
                <p>
                    <strong>Телефон: +7 (499) 110-77-67</strong>
                </p>
                <p>
                    <strong>E-</strong>
                    <strong>mail: </strong>
                    <strong>ultranout@</strong>
                    <strong>gmail.</strong>
                    <strong>com</strong>
                </p>
                <p>
                    <em>
                        Обращаем ваше внимание на то, что данный интернет-сайт
                        http://cristina.ultranout.ru носит исключительно
                        информационный характер и ни при каких условиях не
                        является публичной офертой, определяемой положениями
                        Статьи 437 п.2 Гражданского кодекса Российской
                        Федерации. Для получения подробной информации о
                        технических характеристиках и стоимости указанных
                        товаров и (или) услуг, пожалуйста, обращайтесь к
                        администрации сайта по телефонам:{' '}
                    </em>
                    <strong>
                        <PhoneLink></PhoneLink>
                    </strong>
                </p>
                <div
                    className="zayava-button"
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <a
                        href="https://ultranout.ru/old/wp-content/uploads/2024/01/obrazec-zajavlenija-na-vozvrat-tovara.doc"
                        download>
                        <Button bsPrefix="btn button_main_color" size="lg">
                            ОБРАЗЕЦ ЗАЯВЛЕНИЯ НА ВОЗВРАТ
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Warranty;
