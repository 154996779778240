import { Button, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import PaymentForm from '../payment-form';
import './address-form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const phoneNumberRegEx =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

const schema = yup.object().shape({
    firstName: yup.string().required('Это обязательное поле'),
    lastName: yup.string(),
    address: yup.string(),
    city: yup.string(),
    phone: yup
        .string()
        .required('Это обязательное поле')
        .matches(phoneNumberRegEx, 'Неправильный номер телефона'),
    email: yup
        .string()
        .required('Это обязательное поле')
        .email('Неправильный email'),
});

const AddressForm = ({
    products,
    paymentMethods,
    initialValues,
    setFormValues,
    selectedMethod,
    setSelectedMethod,
    onSubmitOrder,
}) => {
    const handleSubmitOrder = (values) => {
        onSubmitOrder(values);
    };
    const navigate = useNavigate();

    return (
        <div
            className="checkout_wrapper"
            style={{
                padding: '10px',
                minWidth: '100%',
            }}>
            <Button
                bsPrefix="btn button_main_color checkout-go-back"
                onClick={() => navigate(-1)}>
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: '5px' }}
                />
                Вернуться в корзину
            </Button>
            <h2>Оплата и доставка</h2>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmitOrder}>
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <div
                        className='address-form_wrapper'
                        >
                        <Form
                            className='address-form'
                            noValidate
                            onSubmit={handleSubmit}
                            onBlur={handleBlur}>
                            <div className="address-form_first-row">
                                <Form.Group
                                    as={Col}
                                    controlId="formGridFirstName">
                                    <Form.Label>
                                        Имя
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        placeholder="Введите имя"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        isValid={
                                            touched.firstName &&
                                            !errors.firstName
                                        }
                                        isInvalid={!!errors.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    controlId="formGridLastName">
                                    <Form.Label>
                                        Фамилия (необязательно)
                                    </Form.Label>
                                    <Form.Control
                                        placeholder="Введите фамилию"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        isValid={
                                            touched.lastName && !errors.lastName
                                        }
                                    />
                                </Form.Group>
                            </div>

                            <Form.Group
                                className="mb-3"
                                controlId="formGridAddress">
                                <Form.Label>Адрес (необязательно)</Form.Label>
                                <Form.Control
                                    placeholder="например: Улица Пушкина, д.1, кв.10"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    isValid={touched.address && !errors.address}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="formGridCity">
                                <Form.Label>Город (необязательно)</Form.Label>
                                <Form.Control
                                    placeholder="Ваш город"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    isValid={touched.city && !errors.city}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="formGridPhoneNumber">
                                <Form.Label>
                                    Телефон
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Ваш номер телефона"
                                    name="phone"
                                    type="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    isValid={
                                        touched.phone &&
                                        !errors.phone
                                    }
                                    isInvalid={!!errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phone}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="formGridEmail">
                                <Form.Label>
                                    Email
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Ваш email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <h2>Детали</h2>
                            <Form.Group
                                className="mb-3"
                                controlId="formGridDetails">
                                <Form.Label>
                                    Примечание к заказу (необязательно)
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="details"
                                    value={values.details}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form>
                        <PaymentForm
                            products={products}
                            paymentMethods={paymentMethods}
                            selectedMethod={selectedMethod}
                            setSelectedMethod={setSelectedMethod}
                        />
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default AddressForm;
