import PageTitle from '../../components/page-title/pageTitle';
import { CompareTable } from './components';

const Compare = () => {
    return (
        <>
            <PageTitle title="Сравнение" bottomText="Главная / Сравнение" />
            <CompareTable />
        </>
    );
};

export default Compare;
