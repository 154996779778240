import AddressForm from '../../components/address-form';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ModalOnConfirmOrder } from '../../components/modal-on-confirm-order/ModalOnConfirmOrder';
import { ModalPurchaseWarning } from '../../components/modal-purchase-warning';
import { useToggle } from '../../helpers/hooks/useToggle';

const paymentMethods = [
    {
        id: 'cheque',
        title: 'Оплата наличными при получении в Москве',
        description:
            'Оплата наличными',
    },
    {
        id: 'lifepay',
        title: 'Оплата картой любого банка на сайте через защищенный протокол',
        description:
            'Приём платежей обеспечивает платёжная платформа Lifepay',
    },
]

const Checkout = () => {
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [showConfirmModal, toggleConfirmModal] = useToggle();
    const [showWarningModal, toggleWarningModal] = useToggle();
    const [formValues, setFormValues] = useState({});
    const [params, setParams] = useSearchParams();
    const [selectedMethod, setSelectedMethod] = useState('cheque');

    useEffect(() => {
        const cartId = localStorage.getItem('cart_id');

        axios.get('https://login.yandex.ru/info?', {
            headers: {
                Authorization: `OAuth ${cartId}`
            }
        })
            .then(response => {
                setFormValues({
                    ...formValues,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    email: response.data.emails[0],
                    phone: response.data.default_phone.number,
                })
            })
    },[])

    useEffect(() => {
        const instantPurchaseSku = params.get('instantPurchaseSku');
        if (instantPurchaseSku) {
            axios.get(`${process.env.REACT_APP_API_URL}/product?sku=${instantPurchaseSku}`).then((response) => {
                setCart([{ ...response.data, quantity: 1 }])
            });
        }
    }, [])

    const navigate = useNavigate();

    //TODO убрать когда курс валюты стабилизируется
    const exchangeRateWarning = (formValues) => {
        const hasBackorderPositions = cart.some(element => element.stockQuantity === 0);
        if (selectedMethod !== 'cheque' && hasBackorderPositions) {
            toggleWarningModal();
        } else {
            handlePaymentRequest(formValues);
        }
    }

    const handlePaymentRequest = (formValues) => {
        if (showWarningModal) {
            toggleWarningModal();
        }
        toggleConfirmModal()
        const orderBody = {
            paymentMethod: selectedMethod,
            paymentMethodTitle: selectedMethod.title,
            total: cart.reduce(
                (total, product) =>
                    total + (selectedMethod === 'cheque' ? product.salePrice : product.regularPrice) * product.quantity,
                0,
            ),
            customerNote: formValues.details,
            shippingInfo: {
                name: formValues.firstName,
                lastName: formValues.lastName,
                address: formValues.address,
                city: formValues.city,
                phone: formValues.phone,
                email: formValues.email,
            },
            products: cart.map(product => {
                return {
                    ...product,
                    price: selectedMethod === 'cheque' ? product.salePrice : product.regularPrice,
                    quantity: product.quantity
                }
            })
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/orders/create`, { data: orderBody })
            .then((response) => {
                toggleConfirmModal();
                if (selectedMethod === 'cheque') {
                    window.ym(87126302,'reachGoal','cart.cheque_order');
                    navigate(`/checkout/success/${response.data}`);
                    setCart([]);
                    localStorage.setItem('cart', JSON.stringify([]));
                } else {
                    window.ym(87126302,'reachGoal','cart.online_payment_order');
                    window.location.href = response.data;
                }
            })
            .catch((error) => console.log(error));
    };

    return (
        <div
            className="checkout"
            style={{
                display: 'flex',
            }}>
            <ModalOnConfirmOrder show={showConfirmModal} />
            <ModalPurchaseWarning show={showWarningModal} onHide={toggleWarningModal} onConfirm={() => handlePaymentRequest(formValues)}/>
            <AddressForm
                products={cart}
                paymentMethods={paymentMethods}
                initialValues={formValues}
                setFormValues={setFormValues}
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                //TODO вернуть на handlePaymentRequest когда курс успокоится
                onSubmitOrder={exchangeRateWarning}
            />
        </div>
    );
};

export default Checkout;
