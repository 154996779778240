import PageTitle from '../../components/page-title/pageTitle';
import { Accordion } from 'react-bootstrap';
import styles from './options.module.css'
import { PhoneLink } from '../../components/phone_lev_template';

const accordionItems = [
    {
        header: 'Гравировка русских букв, 1500р',
        text: 'Мы привозим ноутбуки из Европы или США, поэтому раскладка клавиатуры бывает разной (английская/немецкая/др). Наши специалисты делают гравировку русских букв заводского качества с сохранением подсветки для любого типа клавиатуры. Также возможно нанесение дополнительных специальных символов, если они отсутствуют. Итоговый результат - не отличается от оригинальной ENG-клавиатуры, по расположению английских и русских букв. За примерами наших работ обращайтесь к менеджеру.',
    },
    {
        header: 'Модернизация памяти/Модернизация SSD',
        text: 'Если модель ноутбука позволяет, наши специалисты могут расширить память/SSD Вашего ноутбука до максимально возможной. Цена рассчитывается индивидуально в зависимости от сложности работы и цены комплектующих. Для клиентов, купивших ноутбук в нашем магазине, модернизация доступна по цене комплектующих.',
    },
    {
        header: 'Русификация Windows',
        text: 'Наши специалисты могут русифицировать Вашу операционную систему - мы устанавливаем русский язык стандартными средствами Windows, на гарантию от официального поставщика и на лицензию это никак не влияет, Вы можете использовать оригинальный англоязычный Windows и добавлять в него русский пакет стандартными средствами или скачать с сайта Microsoft официальный дистрибутив русского языка и установить его заново, с сохранением лицензионного ключа, прошитого в БИОС',
    },
];

export function Options() {
    return (
        <div className={styles.optionsWrapper}>
            <PageTitle title={'Дополнительные услуги'} bottomText={'123'} />
            <div className={styles.options}>
                <p>
                    Наш магазин специализируется на торговле ноутбуками, Вы можете
                    подобрать подходящую именно Вам модель из наличия или на заказ.
                    Для большего комфорта и удобства по желанию клиента мы также
                    предоставляем сопутствующие услуги:
                </p>
                <Accordion alwaysOpen className={styles.accordion}>
                    {accordionItems.map((item, index) => (
                        <Accordion.Item eventKey={index.toString()}>
                            <Accordion.Header className={styles.accordionHeader}><strong>{item.header}</strong></Accordion.Header>
                            <Accordion.Body>{item.text}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                <p>Данные услуги не влияют на гарантию, которую мы предоставляем от нашего Сервисного Центра при покупке.</p>
                <p className={styles.question}>Задайте нам вопрос: <PhoneLink></PhoneLink>; <a href={'mailto: ultranout@gmail.com'}> ultranout@gmail.com </a></p>
            </div>
        </div>
    );
}
